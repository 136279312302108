import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders, HttpParams  } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FormsService {
  URL=environment.urlBackend
  token:any=localStorage.getItem('token_Pro')
  constructor(private http:HttpClient) { }


  getForms(id:any){
    const token=localStorage.getItem('token_Pro')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)

    return this.http.get<any>(`${this.URL}`+'affectation/getmyform/'+id,{headers: headers})  
  
  }
  getAllForm(){
    const token=localStorage.getItem('token_Pro')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)

    return this.http.get<any>(`${this.URL}`+'forms/getforms/',{headers: headers})  
  }

  necessaryFormData(): Observable<any> {
    const token = localStorage.getItem('token_Pro');
    const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
  
    return this.http.get<any>(`${this.URL}` + 'forms/necessaryformdata/', { headers: headers }).pipe(
      shareReplay(1)
    );
  }

  getFormsInsideFolders(id:any){
    return this.http.get<any>(`${this.URL}doctor/forms`);

  }




  getAllFormPagination(page: number, perPage: number) {
    const token = localStorage.getItem('token_Pro');
    const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
  
    return this.http.get<any>(
      `${this.URL}forms/getforms/?page=${page}&perPage=${perPage}`,
      { headers: headers }
    );
  }

  
  getFormsPage(page: number): Observable<any> {
    return this.http.get<any>(`${this.URL}forms/getformskip/${page}`);
  }
  getAffectation(idUser:any){
    const token=localStorage.getItem('token_Pro')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)

    return this.http.get<any>(`${this.URL}`+'affectation/getaffectation/'+idUser,{headers: headers})  
  
  }
  affectForm(id:any,body:any){
    const token=localStorage.getItem('token_Pro')
    const headers=new HttpHeaders().set('authorization','Bearer '+token)

    return this.http.post<any>(`${this.URL}`+'affect/addaffectation',body,{headers: headers})  
  
  }


    getAllVersions(idUser:any,idDooc:any,idForm:any ){
      const token=localStorage.getItem('token_Pro')
      const headers=new HttpHeaders().set('authorization','Bearer '+token)
  
      return this.http.get<any>(`${this.URL}+affect/getresponsetriesdates${idUser}/${idDooc}/${idForm}`,{headers: headers})  
    
    }

    getBilan(userId:any,doctorId:any ){
      const token=localStorage.getItem('token_Pro')
      const headers=new HttpHeaders().set('authorization','Bearer '+token)
  
      return this.http.get<any>(`${this.URL}response/bilan/${userId}/${doctorId}`)
    }


    // generateBilanPDF(data: any): Observable<{ pdfUrl: string }> {
    //   return this.http.post<{ pdfUrl: string }>(`${this.URL}doctor/generate-pdf`, data);
    // }

    generateBilanPDF(data: any): Observable<any> {
      return this.http.post(`${this.URL}response/generate-pdf`, data, { responseType: 'arraybuffer' });
    }
    
    
  }