import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  URL=environment.urlBackend
  private _FavoritePath =this.URL+'forms/';
  
  constructor(private http: HttpClient) {}

  addToFavorites(doctorId: string, form: string): Observable<any> {
    return this.http.post(`${this._FavoritePath}addtofavorites/${doctorId}/${form}`, {});
  }

  removeFromFavorites(doctorId: string, form: string): Observable<any> {
    return this.http.post(`${this._FavoritePath}removefromfavorites/${doctorId}/${form}`, {});
  }

  isFavorite(doctorId: string, form: string): Observable<any> {
    return this.http.get(`${this._FavoritePath}isfavorite/${doctorId}/${form}`);
  }
  getFavorites(doctorId: string): Observable<any> {
    return this.http.get<any>(`${this._FavoritePath}/getfavorites/${doctorId}`);
  }
}
